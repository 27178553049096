.cms {
    width: 100%;
    display: inline-block;
}

/* 
====================================
    Navigation
====================================
*/
.left-menu {
    float: left;
    position: fixed;
    width: 250px;
    background: var(--dashboard-nav);
    height: 100vh;
}

.left-menu a > img {
    margin: 2.5rem;
}

nav .dashboard-icon {
    position: absolute;
    right: 30px;
    top: 40px;
    background-image: url(/images/icons/icon_general_burger_shadow.png);
    width: 29px;
    height: 25px;
    z-index: 10;
    cursor: pointer;
    display: none;
    text-shadow: 1px 1px var(--black);
}

.hide-dashboard-menu {
    display: block;
}

.textAreaField > textarea{
    background: white !important;
    border: 0.5px solid lightgrey !important; 
}

.hide {
    display: none;
}

.verify-button {
    opacity: 0.9;
    background: var(--primary) !important;
    color: var(--white) !important;
    margin-bottom: 1rem !important;
}

.storno-button{
    margin-left: 0.2rem !important; 
    margin-bottom: 1rem !important;
    background: #fb0303 !important; 
    color: var(--white) !important;
}

.cancel-button {
    opacity: 0.9;
    background: var(--dark) !important;
    color: var(--white) !important;
    margin-bottom: 1rem !important;
}

.verify-button:hover {
    opacity: 1;
}

.cancel-button:hover {
    opacity: 1;
}

.dropdown [role="option"].item .text {
    margin-left: 0 !important;
}

.active-card {
    background: var(--dark) !important;
    color: var(--white) !important;
}

/* 
====================================
    Content Block
====================================
*/
.content-container {
    float: right;
    width: calc(100% - 250px);
    font-family: "AvenirNextLTPro";
}

.content-container > header {
    min-height: 70px;
    border-bottom: 1px solid var(--light);
    background-color: var(--white);
    padding: 2rem;
    font-weight: bold;
    padding-left: 2rem;
    line-height: 30px;
    font-size: 15px;
    color: var(--primary);

    -webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.2s; /* Firefox < 16 */
    -ms-animation: fadein 0.2s; /* Internet Explorer */
    -o-animation: fadein 0.2s; /* Opera < 12.1 */
    animation: fadein 0.2s;
}

.content-container > section {
    padding: 1rem;
    color: var(--dark);
    font-size: 14px;

    -webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.2s; /* Firefox < 16 */
    -ms-animation: fadein 0.2s; /* Internet Explorer */
    -o-animation: fadein 0.2s; /* Opera < 12.1 */
    animation: fadein 0.2s;
}

/* 
====================================
    Dashboard Interfaces - Bundles
====================================
*/
.bundle-active {
    padding-left: 10px;
    margin-top: 10px;
    font-weight: bold;
}

.bundles > a {
    text-decoration: none;
}

.bundles button {
    background: var(--primary);
    border: none;
    padding: 1rem;
    color: var(--white);
    opacity: 0.8;
    border-radius: 5px;
    margin-left: 3px;
    cursor: pointer;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.bundles button:hover {
    opacity: 1;
}

div.item-label {
    width: 100%;
    padding: 1.5rem;
    background: var(--light);
    border: 1px solid var(--light);
    border-bottom: 3px solid var(--primary);
    text-align: center;
    border-radius: 5px;
    margin: 0.2rem;
    display: inline-block;
    cursor: pointer;
    opacity: 0.9;
    color: var(--dark);
}

div.item-label:hover {
    opacity: 1;
}

div.item-label > i {
    font-size: 3rem;
    color: var(--primary);
}

div.item-label > .tag {
    padding: 0.5rem;
    margin: 0.1rem;
    background: var(--warning);
    border-radius: 5px;
}

div.item-label > .price {
    background: var(--success);
}

div.item-label > h1 {
    color: var(--primary);
}

div.item-label > span {
    text-align: left !important;
    font-size: 1rem;
    font-weight: bold;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* 
====================================
    Dashboard Buttons
====================================
*/
.form-button,
.toggle-button {
    height: 63px;
    padding: 0px 10px;
    border: 2px solid var(--light-grey);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: var(--light-grey);
    background-color: transparent;
    min-width: 192px;
    margin-top: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.toggle-button {
    min-width: 0px;
}

.form-button-small {
    height: 33px;
}

.form-button-very-small {
    height: 22px;
    min-width: 0px;
    margin-top: 5px;
}

button.menu-link:first-child {
    border-top: 1px solid var(--dashboard-nav);
}

button.menu-link {
    outline: 0;
    font-family: "AvenirNextLTPro";
    font-weight: bold;
    color: var(--white);
    border: none;
    cursor: pointer;
    border-left: 2px solid var(--dark);
    background-color: var(--dashboard-nav);
    font-size: 14px;
    width: 100%;
    padding: 1rem;
    opacity: 0.6;
    text-align: left;
}

button.menu-link > span {
    padding-left: 1rem;
}

button.menu-link > img {
    float: right;
    padding: 4px;
}

button.menu-link:hover,
button.menu-link.active {
    opacity: 1;
    /*font-weight: bold;*/
    background: var(--dashboard-nav-hover);
    border-left: 2px solid var(--primary);
}

.form-button:hover,
.toggle-button.toggle-button--down {
    background-color: var(--primary);
    color: var(--white);
    border-color: var(--primary);
}

.form-button.active {
    background-color: var(--primary);
    color: var(--white);
    border-color: var(--primary);
    opacity: 0.9;
}

.form-button.active:hover {
    opacity: 1;
}

.form-button:disabled {
    pointer-events: none;
    color: var(--light);
    border-color: var(--light);
}

/* Form Elements :( */
label.form-label {
    display: block;
    font-weight: bold;
    font-size: 14px;
    padding: 20px 0px 0px 0px;
}

label.form-label span.red {
    color: red;
}

div.form-input {
    display: block !important;
    position: relative;
}

input.form-input,
div.form-input input,
textarea.form-input {
    padding: 13px 16px;
    line-height: 26px;
    border: 1px solid var(--light);
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 10px 0px;
    color: var(--dark);
    display: block;
}

select.form-input {
    height: 52px;
    border: 1px solid var(--light);
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 10px 0px;
    color: var(--dark);
    display: block;
}

.form-input:focus {
    border: 1px solid var(--primary);
}

form.form-dirty input.form-input:invalid {
    border-color: red;
}

/* Form Types */
#login-form {
    width: 60%;
}

/*
====================================
    Bug fixes
====================================
 */
.dropdown .text {
    margin-top: 0rem !important;
}

.dropdown .default {
    margin-top: 0.6rem !important;
}


.ui.circular.icon.button {
    max-height: 2.6rem;
    max-width: 2.5rem;
}

.item .content {
    margin-left: 2rem !important;
}

.item .facebook.big.icon {
    width: 2rem !important;
}
.item .content {
    margin-left: 0 !important;
}

/*
====================================
    Tables & Pagination
====================================
*/
.rsdt-sortable-icon {
    font-weight: normal;
}

.rsdt.rsdt-container {
    overflow: none !important;
}

.ui.search .prompt {
    border-radius: 0 !important;
}

.rsdt-paginate {
    text-align: left !important;
}

.table-search .input {
    width: 100% !important;
}

.table-search .prompt {
    width: 100% !important;
}

.table-search.search {
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.ui.table {
    border-radius: 0 !important;
}

.ui.grid {
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
}

.header-block {
    margin-bottom: 1rem !important;
    background: var(--light);
}

.item-header {
    font-weight: bold;
    width: 20rem;
}

/* 
====================================
   Form Elements
====================================
*/
input,
select,
textarea {
    border-radius: 0 !important;
    border: 1px solid lightgrey;
}

.ui.selection.dropdown {
    border-radius: 0 !important;
}

.dropdown [role="option"].item .text {
    margin-left: 1.5rem;
}

.field .dropdown .icon {
    /* height: 100% !important;
    display: flex !important;
    align-items: center !important; */
}

.list-item-custom-styles .ui.dropdown > .dropdown.icon {
    margin-left: 0.5rem !important;
}

/* 
====================================
    Styles for Responsive Design
====================================
*/
@media (max-width: 780px) {
    nav .dashboard-icon {
        display: block;
    }

    .hide-dashboard-menu {
        display: none !important;
    }

    .left-menu {
        float: none;
        width: 100%;
        height: auto;
        position: relative;
    }

    /*.left-menu img {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }*/
    .content-container {
        float: none;
        width: 100%;
    }

    .content-container > header {
        text-align: center;
        font-size: 13px;
        padding: 1rem;
    }

    /* Buttons */
    .form-button {
        height: 50px;
        width: 100%;
    }

    /* Forms */
    #login-form {
        width: 100%;
    }

    /* Bundles */
    .bundle button {
        display: block;
        width: 100% !important;
    }

    div.item-label {
        display: block;
    }
}
