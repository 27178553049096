
.invisible-box {
    position: relative;
    background: transparent;
    padding: 2rem 2rem 0;
    height: 100% !important;
}

.invisible-box form {
    height: 100% !important;
}

.invisible-box form input, form textarea {
    background-color: #EBEBEB !important;
    border: 0 !important;
    border-radius: 3px;
    padding: 0.8rem 1.3rem !important;
}


.invisible-box form button {
    display: block;
    text-align: center;
    width: 100%;
    background: #D60D8C;
    color: white;
    border: 0;
    padding: 1.6rem 0;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    transition: .25s ease;
}

.invisible-box form button:hover {
    background: #eb44ad;
}
