div.website h1 {
    background-image: url(/images/logos/logo_big.png);
    background-repeat: no-repeat;
    width: 167px;
    height: 53px;
    position: absolute;
    left: 50%;
    top: 10px;
    margin-left: -650px;
    z-index: 1;
}

div.website h1 span {
    display: none;
}

.stickyBar nav {
    background: var(--black);
    height: 50px;
    width: 100%;
}

.stickyBar a h1 {
    background-size: 100% auto;
    width: 70px;
    top: 15px;
    background-image: url(/images/logos/logo.png);
    background-repeat: no-repeat;
}

.stickyBar nav ul {
    padding-top: -5px;
}

div.website nav ul {
    position: absolute;
    right: 50%;
    margin-right: -650px;
    top: 0px;
    z-index: 1;
}

div.website nav ul li {
    float: left;
    list-style: none;
    padding-right: 30px;
}

.link {
    cursor: pointer;
}

div.website nav ul li a {
    color: var(--white);
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
}

div.website nav ul li a:hover {
    text-decoration: underline;
}

/* Carousel from React */
.carousel .thumbs-wrapper {
    display: none;
}

.carousel {
    height: 100vh;
}

.carousel img {
    display: block;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

div.website header .carousel h2 {
    font-size: 60px;
    color: var(--white);
    text-align: left;
    text-transform: uppercase;
    text-shadow: 2px 1px var(--white);
    position: absolute;
    background: none;
    left: 50%;
    bottom: 50%;
    margin-left: -650px;
    margin-bottom: 80px;
}

div.website header .carousel p {
    position: absolute;
    left: 50%;
    bottom: 50%;
    text-align: left;
    margin-left: -650px;
    margin-bottom: 0px;
    color: var(--white);
    width: 400px;
}

div.website header .carousel h2 span {
    display: inline-block;
    background-image: url(/images/icons/design_dot.png);
    background-repeat: no-repeat;
    width: 25px;
    height: 30px;
    background-position: center bottom;
}

div.website header .carousel p a {
    color: var(--primary);
    font-weight: bold;
}

div.website header .download {
    position: absolute;
    left: 50%;
    margin-left: -650px;
    bottom: 50%;
    margin-bottom: -240px;
    background-image: url(/images/icons/design_arrow.png);
    background-position: right top;
    background-repeat: no-repeat;
    width: 450px;
    height: 100px;
}

div.website header .carousel .download p {
    font-size: 14px;
    color: var(--white);
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 0px;
    position: static;
}

div.website header .download > a {
    position: absolute;
    width: 189px;
    height: 66px;
    background-repeat: no-repeat;
}

div.website header .download > a.android {
    left: 0px;
    top: 40px;
    background-image: url(/images/icons/icon_general_playstore.png);
}

div.website header .download > a.ios {
    left: 200px;
    top: 40px;
    background-image: url(/images/icons/icon_general_appstore.png);
}

div.website header .download > a span {
    display: none;
}

div.website header .indicator {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 30px;
}

div.website header .indicator span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    border: 1px solid var(--white);
    cursor: pointer;
}

div.website header .indicator span.active {
    background-color: var(--white);
}

div.website section.section1 {
    padding: 80px 20px;
    color: var(--dark);
}

div.website section.section1 h2,
div.website section.section1 p {
    font-size: 24px;
    color: var(--light-grey);
    text-align: center;
    padding-bottom: 60px;
    text-transform: uppercase;
}

div.website section.section1 > p {
    padding-top: 30px;
    padding-bottom: 30px;
    font-weight: bold;
}

div.website section.section1 > p a {
    color: var(--primary);
}

div.website section.section1 article {
    max-width: 1300px;
    margin: 0px auto;
    overflow: auto;
}

div.website section.section1 article > div {
    float: left;
    width: 33%;
    box-sizing: border-box;
    padding-right: 20px;
    padding-top: 0px;
    text-align: center;
}

div.website section.section1 article > div + div {
    padding-left: 10px;
    padding-right: 10px;
}

div.website section.section1 article > div + div + div {
    padding-left: 20px;
    padding-right: 0px;
}

div.website section.section1 article > div > img {
    width: 50%;
    background-image: url(/images/public/home/mobile_showcase_search.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 88% auto;
}

div.website section.section1 article > div + div > img {
    background-image: url(/images/public/home/mobile_showcase_activity.png);
}

div.website section.section1 article > div + div + div > img {
    background-image: url(/images/public/home/mobile_showcase_order.png);
}

div.website section.section1 article > div h3 {
    font-size: 46px;
    color: var(--dark);
    font-weight: normal;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}

div.website section.section1 article > div p {
    text-align: center;
    font-size: 14px;
}

div.website section.section2 {
    padding: 80px 20px;
    color: var(--dark);
    background-color: var(--light);
}

div.website section.section2 h2 {
    color: var(--light-grey);
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    max-width: 1300px;
    margin: 0px auto;
    padding-bottom: 20px;
}

div.website section.section2 #login-form {
    max-width: 1300px;
    margin: 0px auto;
}

div.website section.section2 #login-form > a {
    text-transform: uppercase;
    color: var(--dark);
    font-size: 16px;
    font-weight: bold;
}

div.website section.section2 #login-form > a:hover {
    text-decoration: none;
}

div.website section.section3 {
    padding: 80px 20px;
    color: var(--dark);
}

div.website section.section3 h2 {
    font-size: 24px;
    color: var(--light-grey);
    max-width: 1300px;
    margin: 0px auto;
    padding-bottom: 20px;
    text-transform: uppercase;
}

div.website section.section3 h3 {
    font-size: 46px;
    color: var(--dark);
    max-width: 1300px;
    margin: 0px auto;
    font-weight: normal;
    padding-bottom: 10px;
    background-image: url(/images/public/home/design_underline_long.png);
    background-repeat: no-repeat;
    background-position: left bottom;
}

div.website section.section3 article {
    padding-top: 20px;
    max-width: 1300px;
    margin: 0px auto;
    overflow: auto;
    cursor: pointer;
}

div.website section.section3 article > div {
    width: 100%;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 10px;
    opacity: 0.8;
}

div.website section.section3 article > div:hover {
    opacity: 1;
}

div.website section.section3 article > div:nth-child(5) {
    clear: left;
}

div.website section.section3 article > div h4 {
    cursor: pointer;
    font-size: 16px;
    color: var(--light-grey);
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--light);
    text-transform: uppercase;
}

div.website section.section3 article > div p {
    cursor: default !important;
    display: none;
    font-size: 14px;
}

div.website section.section3 article > div p.opened {
    display: block;
}

div.website section.section3 article > div p a {
    color: var(--primary);
    text-decoration: underline;
}

div.website section.section3 article > div p a:hover {
    text-decoration: none;
}

div.website section.section3 article > p {
    clear: left;
    font-size: 16px;
    max-width: 1300px;
    margin: 0px auto;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
}

div.website section.section4 {
    padding: 160px 20px;
    color: #313847;
    background-color: var(--white);
}

div.website section.section4 h2 {
    color: var(--light-grey);
    font-size: 24px;
    max-width: 1300px;
    margin: 0px auto;
    text-transform: uppercase;
    padding-bottom: 10px;
}

div.website section.section4 h3 {
    font-size: 46px;
    color: var(--dark);
    max-width: 1300px;
    margin: 0px auto 40px auto;
    font-weight: normal;
    padding-bottom: 10px;
    background-image: url(/images/public/home/design_underline_short.png);
    background-repeat: no-repeat;
    background-position: left bottom;
}

div.website section.section4 p {
    font-size: 14px;
    color: var(--dark);
    max-width: 1300px;
    margin: 0px auto;
}

div.website section.section2 #login-form,
div.website section.section4 #contactForm {
    max-width: 1300px;
    margin: 0px auto;
    padding-top: 30px;
}

div.website section.section2 #login-form form,
div.website section.section4 #contactForm form {
    text-align: center;
}

div.website section.section2 #login-form form div.half-cell,
div.website section.section4 #contactForm form div.half-cell {
    float: left;
    width: 50%;
    clear: none;
    padding-right: 20px;
    box-sizing: border-box;
}

div.website section.section2 #login-form form div.half-cell + div.half-cell,
div.website section.section4 #contactForm form div.half-cell + div.half-cell {
    padding-left: 20px;
    padding-right: 0px;
}

div.website section.section2 #login-form form div,
div.website section.section4 #contactForm form div {
    clear: both;
    text-align: left;
    padding-bottom: 40px;
}

div.website section.section2 #login-form form div label,
div.website section.section4 #contactForm form div label {
    display: block;
    padding-bottom: 20px;
    font-size: 16px;
    color: var(--light-grey);
    text-transform: uppercase;
    font-weight: bold;
}

div.website section.section2 #login-form form div input,
div.website section.section4 #contactForm form div input,
div.website section.section4 #contactForm form div textarea {
    width: 100%;
    resize: none;
    padding-bottom: 20px;
    height: 40px;
    border: 0px;
    border-bottom: 2px solid var(--light-grey);
    background-color: transparent;
    font-size: 24px;
}

div.website section.section2 #login-form form button,
div.website section.section4 #contactForm form button {
    min-width: 192px;
    height: 63px;
    padding: 0px 10px;
    margin-bottom: 40px;
    border: 2px solid var(--primary);
    background-color: var(--primary);
    color: var(--white);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

div.website section.section2 #login-form form button.reverse {
    color: var(--light-grey);
    background-color: transparent;
    border: 2px solid var(--light-grey);
    margin-right: 40px;
}

div.website footer {
    height: 250px;
    background-color: var(--black);
    position: relative;
}

#footer-logo {
    position: absolute;
    left: 50%;
    margin-left: -650px;
    top: 50%;
    transform: translate(0, -50%);
}

div.website footer .social {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
}

div.website footer .social a {
    display: inline-block;
    background-image: url(/images/icons/icon_general_social_sites.png);
    background-repeat: no-repeat;
    width: 50px;
    height: 23px;
}

div.website footer .social a:first-child {
    background-position: left top;
}

div.website footer .social a:first-child:hover {
    background-position: left bottom;
}

div.website footer .social a:first-child + a {
    background-position: center top;
    margin: 0px 40px;
}

div.website footer .social a:first-child + a:hover {
    background-position: center bottom;
}

div.website footer .social a:first-child + a + a {
    background-position: right top;
}

div.website footer .social a:first-child + a + a:hover {
    background-position: right bottom;
}

div.website footer .social a span {
    display: none;
}

div.website footer div.text {
    position: absolute;
    right: 50%;
    top: 50%;
    margin-right: -650px;
    transform: translate(0, -50%);
    min-width: 320px;
}

.bank-logo {
    text-align: right;
}

.bank-logo img {
    margin: 20px;
}

div.website footer div.text span {
    color: var(--white);
    text-transform: uppercase;
    display: block;
    font-size: 13px;
    text-align: right;
}

div.website footer div.text span:first-child {
    white-space: nowrap;
}

div.website footer div.text span:last-child {
    text-transform: none;
}

@media (min-width: 721px) {
    div.website .blackMenu nav {
        background-color: var(--black);
        height: 40px;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        z-index: 1;
    }

    div.website .blackMenu nav ul {
        top: 12px;
    }

    div.website .blackMenu h1 {
        background-size: 100% auto;
        width: 70px;
        top: 10px;
        z-index: 5;
        position: fixed;
    }
}

@media (max-width: 720px) {
    div.website > .stickyBar nav {
        background: var(--black);
        height: 60px;
        width: 100%;
    }

    div.website > .stickyBar h1 {
        background-image: url(/images/logos/logo.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        width: 70px;
        top: 15px;
        z-index: 5;
        position: fixed;
    }

    div.website h1 {
        background-image: url(/images/logos/logo.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        width: 70px;
        top: 15px;
        z-index: 5;
        position: fixed;
    }

    div.website nav {
        height: 40px;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        z-index: 1;
    }

    div.website nav .menuIcon {
        position: absolute;
        right: 15px;
        top: 15px;
        background-image: url(/images/icons/icon_general_burger_shadow.png);
        width: 29px;
        height: 25px;
        z-index: 10;
        cursor: pointer;
        text-shadow: 1px 1px var(--black);
    }

    div.website nav ul {
        display: none;
        padding-top: 40px;
        padding-bottom: 20px;
        padding-left: 0px;
        left: 0px;
        top: 0px;
        right: 0px !important;
        background-color: var(--black);
    }

    div.website nav ul.opened {
        display: block;
        margin-top: 0;
    }

    div.website nav ul li {
        float: none;
        text-align: center;
        line-height: 1.7em;
        padding-right: 0px;
    }

    a {
        text-decoration: none;
    }
}

@media (max-width: 1300px) {
    div.website header .download {
        left: 20px;
        margin-left: auto;
    }

    div.website header .carousel h2 {
        left: 20px;
        margin-left: auto;
    }

    div.website header .carousel p {
        left: 20px;
        margin-left: auto;
    }

    div.website h1 {
        left: 20px;
        margin-left: 0px;
    }

    div.website nav ul {
        right: 20px;
        margin-right: 0px;
    }

    div.website section.section1 article > div br {
        display: none;
    }

    div.website section {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }

    #footer-logo {
        left: 20px;
        margin-left: auto;
    }

    div.website footer div.text {
        right: 20px;
        margin-right: auto;
        margin-top: 20px;
    }
}

@media (max-width: 900px) {
    div.website section.section1 article > div {
        width: 50%;
        margin-bottom: 100px;
    }

    div.website section.section1 article > div + div + div {
        clear: both;
        float: none;
        margin: 0px auto 0px auto;
    }

    div.website section.section2 h2 {
        text-align: center;
    }

    div.website section.section3 h2 {
        text-align: center;
    }

    div.website section.section3 h3 {
        text-align: center;
    }

    div.website section.section3 article > div {
        width: 100%;
        clear: none !important;
        padding-bottom: 40px;
    }

    div.website section.section3 article > div:first-child + div + div,
    div.website
    section.section3
    article
    > div:first-child
    + div
    + div
    + div
    + div,
    div.website
    section.section3
    article
    > div:first-child
    + div
    + div
    + div
    + div
    + div
    + div {
        clear: left !important;
    }

    div.website section.section3 article > div p {
        display: none;
    }

    div.website section.section3 article > div.opened {
        padding-bottom: 0px;
    }

    div.website section.section3 article > div.opened p {
        display: block;
        border-bottom: 2px solid var(--light);
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    div.website section.section3 article > div.opened h4 {
        margin-bottom: 0px;
        border-bottom: 0px;
    }

    div.website section.section4 h2 {
        text-align: center;
    }

    div.website section.section4 h3 {
        text-align: center;
        background-position: center bottom;
    }

    #footer-logo {
        left: 50%;
        top: 30px;
        transform: translate(-50%, 0);
    }

    div.website footer div.text {
        left: 50%;
        top: auto;
        right: auto;
        bottom: 20px;
        transform: translate(-50%, 0);
    }

    .bank-logo {
        text-align: center;
    }

    .bank-logo img {
        padding-top: 25px;
        margin-bottom: 5px;
    }

    div.website footer div.text span {
        text-align: center;
    }
}

@media (max-width: 640px) {
    div.website section.section1 article > div {
        width: 100%;
        margin-bottom: 0px;
        padding-top: 100px;
        padding-right: 0px;
    }

    div.website section.section2 #login-form form div.half-cell,
    div.website section.section4 #contactForm form div.half-cell {
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    div.website section.section2 #login-form form button {
        display: block;
        margin-right: 0px !important;
        width: 100%;
        margin-bottom: 20px;
    }

    div.website section.section3 h2 {
        font-size: 20px;
    }

    div.website section.section3 h3 {
        font-size: 35px;
    }

    div.website section.section3 article {
        padding-top: 30px;
    }

    div.website section.section3 article > div {
        width: 100%;
        padding-bottom: 20px;
    }

    div.website section.section4 h2 {
        font-size: 20px;
    }

    div.website section.section4 h3 {
        font-size: 35px;
    }
}

@media (max-width: 468px) {
    div.website header .carousel {
        background-position: 60% center;
    }

    div.website header .carousel .image-center {
        background-position: center center;
    }

    div.website header .carousel h2 {
        margin-bottom: 0px;
        font-size: 40px;
    }

    div.website header .carousel p {
        margin-bottom: -110px;
        width: auto;
        right: 20px;
        color: var(--black) !important;
        text-shadow: 0px 0px 10px var(--white);
    }

    div.website header .download p {
        font-size: 13px;
    }

    div.website header .download {
        width: auto;
        right: 20px;
        background-image: none;
    }

    div.website header .download > a {
        width: 45%;
        background-size: 100% auto;
    }

    div.website header .download > a.ios {
        right: 0px;
        left: auto;
    }
}
