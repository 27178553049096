body {
    font-family: "Metropolis Regular" !important;
}

body > h1, h2, h3, h4, h5 {
    font-family: "Metropolis Regular" !important;
}

body > p {
    font-family: "Metropolis Regular" !important;
}

.navigation {
    display: flex;
    align-items: top;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    background: transparent;
    padding-top: 1rem;
    margin-bottom: 2rem;
}

.navigation .link-section {
    display: inline-block;
    width: 80%;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    cursor: pointer;
}

.navigation .link-section .navigation-item {
    color: #272d45;
    margin-right: 2rem;
}

.navigation-item:hover {
    opacity: 0.8;
}

.navigation .navigation-logo {
    margin: 0 auto;
    width: 400px;
}

.navlogo {
    margin: 0 auto;
}

.dropdown-list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
}

.dropdown-list.open  {
  display: block;
}

.section-for-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Metropolis EXTRA BOLD';
    font-size: 2.3rem;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.action-item {
    line-height: 2.0rem;
    letter-spacing: -1.5px;
    font-weight: bold;
    padding: 2rem;
    margin-bottom: 0rem;
    color: #272d45;
    font-size: 0.8em;
    transform: scale(1);
    transition: all ease-in-out 0.2s;
}

.action-item:hover{
    opacity: 0.8;
    color: #272d45;
    transform: scale(1.1);
}

.content-area{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.content-area p {
    font-family: 'Metropolis BOLD';
    margin: 0 auto;
    width: 80%;
    color: #d60f8d;
    text-align: center;
    font-size: 2rem;
}

.content-area span {
    display: block;
    width: 80%;
    margin: 0 auto;
    font-family: 'Metropolis BOLD';
    text-align: center;
    color: #272d45
}

.foonter-section{
    width: 100%;
    text-align: center;
}

.social-icon-set {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    margin: 0 auto;
    font-size: 3rem;
    margin-top: 5rem;
    margin-bottom: 1rem;
    cursor: pointer;
}

.social-icon-set i:hover {
    opacity: 0.8;
}

.footer-navigation {
    display: flex;
    flex-direction: row;
    align-items: top center;
    justify-content: space-between;
    margin: 0 auto;
    width: 750px;
    color: #272d45;
    font-size: 1.2rem;
    margin-bottom: 0rem;
    cursor: pointer;
    text-transform: uppercase;
}

.footer-navigation:last-child{
    margin-bottom: 5rem;
}

.footer-navigation .footer-link {
    text-align: center;
    font-weight: bold;
    padding: 0.5rem;
}

.footer-navigation .footer-link:hover{
    opacity: 0.8;
}

.facebook-icon{
    position: relative;
    top: -0.8rem;
    background: #272d45;
    color: white;
    font-size: 1.5rem !important;
}

.footer-link, .footer-link:hover {
    color: #272d45;
}

.model-header{
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    color: #272d45;
}

.card-item {
    cursor: pointer;
    opacity: 0.8;
    padding: 2rem;
    transform: scale(1);
    color: #272d45;
    transition: all ease-in-out 0.2s;
    text-align: center;
    text-transform: uppercase;
}

.card-item p {
    font-size: 1.2rem;
    padding-top: 0.7rem;
}

.card-item:hover{
    transform: scale(1.05);
    opacity: 1;
}

.module-selection .ui.menu:not(.vertical) .item {
    display: block;
    font-family: 'Metropolis Regular';
}

.module-selection .secondary.pointing.menu .item:last-child {
    width: 50%;
    color: #d60f8d;
    font-weight: bold;
    text-align: center;
}

.module-selection .secondary.pointing.menu .item.active:last-child {
    color: #d60f8d;
    border-color: #d60f8da1;
}

.module-selection .secondary.pointing.menu .item:first-child {
    width: 50%;
    color: #272d45;
    font-weight: bold;
    text-align: center !important;
}

.module-selection .secondary.pointing.menu .item.active:first-child {
    color: #272d45;
    border-color: #272d45;
}

@media only screen and (max-width: 750px) {
    .footer-navigation {
        font-size: 1rem;
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
        text-align: center;
    }
    
    .footer-navigation:last-child{
        width: 100%;
    }

    .section-for-actions {
        margin-bottom: 0;
    }

    .navigation .link-section {
        font-size: 0.9rem;
    }

    .navigation {
        margin-bottom: 0;
    }

    .olark-launch-button-wrapper {
        display: none;
    }
}

@media only screen and (max-width: 512px) {
    .navigation .link-section .navigation-item {
        margin-right: 1rem;
    }

    .navigation .navigation-logo .navlogo{
        margin: 0rem auto;
        width: 30%;
    }

    .olark-launch-button-wrapper {
        display: none;
    }
}

@media only screen and (max-width: 450px) {
    .navigation .link-section .navigation-item {
        margin-right: 0.5rem;
    }

    .navigation .navigation-logo .navlogo{
        margin: 0rem auto;
        width: 30%;
    }

    .olark-launch-button-wrapper {
        display: none;
    }
}

@media only screen and (max-width: 400px) {
    .navigation {
        display: block;
    }

    .navigation .link-section{
        width: 100%;
        text-align: center; 
    }

    .navigation .navigation-logo{
        margin: 0 auto;
        width: 100%;
    }
    
    .navigation .navigation-logo .navlogo{
        margin: 0rem auto;
        width: 30%;
    }

    .section-for-actions .action-item{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .navigation .link-section {
        text-align: center;
    }

    .olark-launch-button-wrapper {
        display: none;
    }
}