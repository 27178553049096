.hidden {
    display: none;
}

@media (min-width: 320px) {
    .container .btn2 {
        text-align: center;

    }

    .container .btn2 .btnS {
        display: inline-block;
        width: 92%;
        margin-left: auto;
        height: 4rem;
        color: rgb(36, 36, 36);
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-weight: bolder;
        font-size: 1.2rem;
        border-radius: 0;
        border: none;


    }

    .container .back {
        display: flex;
        align-items: center;
        padding: 1rem;
    }
    .container .activity-name {
      padding: 1rem;
      text-align: left;
      line-height: 2rem;
      font-weight: bolder;
      font-size: 1.875rem;
    }

    .container .back .btn {
        font-weight: bold;
        padding: 1.5rem 3.3rem;
        font-size: 1rem;
        color: white;
        background-color: #d60c8c;
        border-radius: 0;
    }

    .container .btn:hover {
        text-decoration: none;
        background-color: #e22c9f !important;
        color: white !important;
    }
}

@media (min-width: 1025px) {
    .container .btn2 {
        text-align: center;

    }

    .container .btn2 .btnS {
        display: inline-block;
        width: 100%;
        margin-left: auto;
        height: 4rem;
        color: rgb(36, 36, 36);
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-weight: bolder;
        border-radius: 0;
        border: none;

    }
    .container .activity-name {
      margin-top: -4rem;
      margin-left: 4rem;
      text-align: left;
      padding-right: 5rem;
      line-height: 2rem;
      font-weight: bolder;
      font-size: 1.875rem;
    }

    .container .back {
        background-color: white;
        padding: 1rem;
        margin-top: -26%;
        width: 35rem;
        height: 12rem;
        position: absolute;
    }

    .container .back .btn {
        position: absolute;
        display: flex;
        align-self: flex-start;
        font-weight: bold;
        padding: 1.5rem 3.3rem;
        font-size: 1.2rem;
        margin-left: 5rem;
        margin-top: 6rem;
        color: white;
        background-color: #d60c8c;
        border-radius: 0;
    }

    .container .btn:hover {
        text-decoration: none;
        background-color: #e22c9f !important;
        color: white !important;
    }

    .hidden {
        display: none;
    }
}