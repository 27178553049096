/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Metropolis Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Regular'), url('Metropolis-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Regular Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Regular Italic'), url('Metropolis-RegularItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Extra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Extra Light'), url('Metropolis-ExtraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Light';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Light'), url('Metropolis-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Thin'), url('Metropolis-Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Extra Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Extra Light Italic'), url('Metropolis-ExtraLightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Light Italic'), url('Metropolis-LightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Thin Italic'), url('Metropolis-ThinItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Medium'), url('Metropolis-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Semi Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Semi Bold'), url('Metropolis-SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Bold'), url('Metropolis-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Bold Italic'), url('Metropolis-BoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Medium Italic'), url('Metropolis-MediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Semi Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Semi Bold Italic'), url('Metropolis-SemiBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Extra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Extra Bold'), url('Metropolis-ExtraBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Extra Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Extra Bold Italic'), url('Metropolis-ExtraBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Black';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Black'), url('Metropolis-Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Metropolis Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Metropolis Black Italic'), url('Metropolis-BlackItalic.woff') format('woff');
    }